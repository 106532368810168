function ScoreBoard({ scores, totalWins }) {
    return (
      <div>
        <h2>ScoreBoard</h2>
        {scores.map((score, index) => (
          <p key={index}>Player {index + 1}: {totalWins[index]} wins</p>
        ))}
      </div>
    );
  }
  
  export default ScoreBoard;
  