import React, { createContext, useContext, useReducer } from 'react';

// Initial state
const initialState = {
  sets: [],
  totalWins: [],
};

// Create context
const GameContext = createContext();

// Reducer function to update state based on action
const gameReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_SET':
      return {
        ...state,
        sets: [...state.sets, action.payload],
      };
    case 'UPDATE_TOTAL_WINS':
      return {
        ...state,
        totalWins: action.payload,
      };
    case 'LOAD_GAME_STATE':
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};

// Context provider component
export const GameProvider = ({ children }) => {
  const [state, dispatch] = useReducer(gameReducer, initialState);

  return (
    <GameContext.Provider value={{ state, dispatch }}>
      {children}
    </GameContext.Provider>
  );
};

// Custom hook to use game context
export const useGameContext = () => useContext(GameContext);
