import { Typography } from '@mui/material';
import React, { useState } from 'react';
import { GameProvider } from './GameContext';
import GameBoard from './components/GameBoard';
import GameSetup from './components/GameSetup';

function App() {
  const [gameStarted, setGameStarted] = useState(false);
  const [numPlayers, setNumPlayers] = useState(2);
  const [playerNames, setPlayerNames] = useState([]);

  const startGame = (numPlayers, playerNames) => {
    setNumPlayers(numPlayers);
    setPlayerNames(playerNames);
    setGameStarted(true);
  };

  return (
    <GameProvider>
      <div className="App"  style={{ width: "85%", margin: "0 auto"}}>
        <Typography gutterBottom variant="h2" component="div">
            Amazonas Dart Open
        </Typography>
        {!gameStarted ? (
          <GameSetup onStart={startGame} />
        ) : (
          <GameBoard numPlayers={numPlayers} playerNames={playerNames} />
        )}
      </div>
    </GameProvider>
    
  );
}

export default App;
