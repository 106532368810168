import { Button, Card, CardContent, Unstable_Grid2 as Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ScoreBoard from './ScoreBoard';

function GameBoard({ numPlayers, playerNames }) {
  const [scores, setScores] = useState(Array(numPlayers).fill(0)); // Optional if you track in-game scores
  const [sets, setSets] = useState([]);
  const [currentSetName, setCurrentSetName] = useState("");
  const [selectedWinner, setSelectedWinner] = useState(null); // New state for selected winner
  const [totalWins, setTotalWins] = useState(Array(numPlayers).fill(0)); // Track total wins for each player

  useEffect(() => {
    // Calculate total wins whenever sets are updated
    const wins = Array(numPlayers).fill(0);
    sets.forEach(set => {
      if (set.winner !== null && set.winner >= 0) {
        wins[set.winner]++;
      }
    });
    setTotalWins(wins);
  }, [sets, numPlayers]);

  const finalizeSet = () => {
    if (selectedWinner === null) {
      alert("Please select a winner for the set.");
      return;
    }
    const newSet = {
      name: currentSetName || `Set ${sets.length + 1}`,
      winner: selectedWinner,
    };
    setSets([...sets, newSet]);
    setScores(Array(numPlayers).fill(0)); // Reset scores for the next set, if relevant
    setCurrentSetName(""); // Reset the set name
    setSelectedWinner(null); // Reset the selected winner
  };

  const saveGame = () => {
    const gameState = {
        numPlayers,
        playerNames,
        sets, // Assuming this already includes detailed win information per set
        totalWins: totalWins, // Assuming you track total wins in state
      };
  
    fetch('https://dart.kalkson.de/saveGameState.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(gameState),
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        alert('Game state saved successfully.');
      } else {
        alert('Failed to save game state.');
      }
    })
    .catch((error) => {
      console.error('Error:', error);
      alert('An error occurred while saving the game state.');
    });
  };
  

  return (
    <div>
        <Grid container spacing={4}>
            <Grid xs={12}>
            <Card>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        Current game
                    </Typography>
                    <TextField
                        type="text"
                        value={currentSetName}
                        onChange={(e) => setCurrentSetName(e.target.value)}
                        placeholder="Name this game"
                    />
                    <div>
                        Select Winner:
                        {[...Array(numPlayers).keys()].map((playerIndex) => (
                        <label key={playerIndex}>
                            <input
                            type="radio"
                            name="winner"
                            value={playerIndex}
                            checked={selectedWinner === playerIndex}
                            onChange={() => setSelectedWinner(playerIndex)}
                            />
                            Player {playerIndex + 1}
                        </label>
                        ))}
                    </div>
                    <Button variant="contained" onClick={finalizeSet}>Finalize Set</Button>
                </CardContent>
            </Card>
            </Grid>        
            <Grid xs={8}>
                <Card>
                    <CardContent>
                        <div>
                            <h2>Past Sets</h2>
                            {sets.map((set, index) => (
                            <div key={index}>
                                <h3>{set.name}</h3>
                                <p>Winner: Player {set.winner + 1}</p>
                            </div>
                            ))}
                        </div>
                    </CardContent>
                </Card>
            </Grid>
            <Grid xs={4}>
            <Card>
                    <CardContent>
                <ScoreBoard scores={scores} totalWins={totalWins} />
                </CardContent>
            </Card>
            </Grid>     
        </Grid>
      
      
      <Button variant="contained"  onClick={saveGame}>Save Game</Button>
    </div>
  );
}

export default GameBoard;
