import React, { useEffect, useState } from 'react';

function GameSetup({ onStart }) {
  const [numPlayers, setNumPlayers] = useState(2);
  const [playerNames, setPlayerNames] = useState(["", ""]);
  const [savedGames, setSavedGames] = useState([]);

  // Function to fetch the list of saved games
  useEffect(() => {
    fetch('https://dart.kalkson.de/listSavedGames.php') // Adjust the URL to your PHP script
      .then(response => response.json())
      .then(data => setSavedGames(data))
      .catch(error => console.error('Error fetching saved games:', error));
  }, []); // Empty dependency array means this effect runs once on mount

  // Function to load a selected saved game
const loadSavedGame = (fileName) => {
    fetch(`https://dart.kalkson.de/loadGameState.php?file=${fileName}`)
      .then(response => response.json())
      .then(data => {
        if (!data.error) {
          setNumPlayers(data.numPlayers);
          setPlayerNames(data.playerNames);
          // Any additional state restoration as needed
          // Optionally, directly start the loaded game
          // onStart(data.numPlayers, data.playerNames, data.sets, data.totalWins);
        } else {
          alert(data.error);
        }
      })
      .catch(error => {
        console.error('Error loading the saved game:', error);
        alert('Failed to load the saved game.');
      });
  };
  

  const handlePlayerCountChange = (e) => {
    const newCount = Number(e.target.value);
    setNumPlayers(newCount);
    setPlayerNames(new Array(newCount).fill("").map((_, index) => playerNames[index] || ""));
  };

  const handleNameChange = (index, name) => {
    const newNames = [...playerNames];
    newNames[index] = name;
    setPlayerNames(newNames);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onStart(numPlayers, playerNames);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>
          Number of Players:
          <input
            type="number"
            value={numPlayers}
            onChange={handlePlayerCountChange}
            min="2"
            max="10"
          />
        </label>
      </div>
      {Array.from({ length: numPlayers }, (_, index) => (
        <div key={index}>
          <label>
            Player {index + 1} Name:
            <input
              type="text"
              value={playerNames[index]}
              onChange={(e) => handleNameChange(index, e.target.value)}
              required
            />
          </label>
        </div>
      ))}
      <button type="submit">Start Game</button>
      <h2>Load a Saved Game</h2>
      <ul>
        {savedGames.map((game, index) => (
          <li key={index} onClick={() => loadSavedGame(game.name)} style={{cursor: 'pointer'}}>
            {game.name} - {game.date}
          </li>
        ))}
      </ul>
    </form>
  );
}

export default GameSetup;
